

























































































































































































import Vue from "vue";
import { productService } from "@/services/product.service";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import { DataListProduct, ResponseProduct } from "@/models/interface/product.interface";
import { debounceProcess } from "@/helpers/debounce";
import { Messages } from "@/models/enums/messages.enum";
import { assetsServices } from "@/services/assets.service";
import { contactServices } from "@/services/contact.service";
import { DataListMasterAsset } from "@/models/interface/assets.interface";
import { ContactData } from "@/models/interface/contact.interface";
import { logisticServices } from "@/services/logistic.service";
import { DataResponseGetListWorkOrder, DataResponseWorkOrderResult } from "@/models/interface/logistic.interface";
import moment from "moment";
import { ResponsePagination } from "@/models/interface/common.interface";
export default Vue.extend({
  name: "ResultWorkOrder",
  data() {
    this.getListProducts = debounceProcess(this.getListProducts, 200);
    this.getListCustomer = debounceProcess(this.getListCustomer, 200)
    this.getUnitCode = debounceProcess(this.getUnitCode, 200)
    this.getListWorkOrderNumber = debounceProcess(this.getListWorkOrderNumber, 200)
    return {
      form: this.$form.createForm(this, { name: 'resultworkorder' }),
      page: this.page || (1 as number),
      limit: this.limit || (10 as number),
      totalData: 0 as number,
      loading: {
        table: false,
        loadingFind: false,
        loadingCustomerMaintenance: false,
        loadingWorkOrderNumber: false,
        loadingCustomerName: false,
        loadingUnitCode: false
      },
      dataListCustomerMaintenance: [] as DataResponseWorkOrderResult[],
      dataWorkOrder: [] as DataResponseGetListWorkOrder[],
      dataCustomer: [] as ContactData[],
      dataUnitCode: [] as DataListMasterAsset[],
      formRules: {
        customerMaintenanceNumber: {
          label: "Customer Maintenance Number",
          name: "customerMaintenanceNumber",
          placeholder: "Insert Customer Maintenance Number",
          decorator: [
            "customerMaintenanceNumber",
            {
              rules: [
                {
                  required: false,
                  message: Messages.VALIDATION_REQUIRED_ERROR,
                },
              ],
            },
          ],
        },
        workOrderNumber: {
          label: "Work Order Number",
          name: "workOrderNumber",
          placeholder: "Insert Work Order Number",
          decorator: [
            "workOrderNumber",
            {
              rules: [
                {
                  required: false,
                  message: Messages.VALIDATION_REQUIRED_ERROR,
                },
              ],
            },
          ],
        },
        customerName: {
          label: "Customer Name",
          name: "customerName",
          placeholder: "Insert Customer Name",
          decorator: [
            "customerName",
            {
              rules: [
                {
                  required: false,
                  message: Messages.VALIDATION_REQUIRED_ERROR,
                },
              ],
            },
          ],
        },
        unitCode: {
          label: "Unit Code",
          name: "unitCode",
          placeholder: "Insert Unit Code",
          decorator: [
            "unitCode",
            {
              rules: [
                {
                  required: false,
                  message: Messages.VALIDATION_REQUIRED_ERROR,
                },
              ],
            },
          ],
        },
        dateFrom: {
          label: "Date From",
          name: "dateFrom",
          placeholder: "Insert Date From",
          decorator: [
            "dateFrom",
            {
              rules: [
                {
                  required: false,
                  message: Messages.VALIDATION_REQUIRED_ERROR,
                },
              ],
            },
          ],
        },
        dateTo: {
          label: "Date To",
          name: "dateTo",
          placeholder: "Insert Date To",
          decorator: [
            "dateTo",
            {
              rules: [
                {
                  required: false,
                  message: Messages.VALIDATION_REQUIRED_ERROR,
                },
              ],
            },
          ],
        },
      },
      columnsTable: [
        {
          title: "Customer Maintenance Number",
          dataIndex: "documentNumber",
          key: "documentNumber",
          width: 250
        },
        {
          title: "Work Order Number",
          dataIndex: "workOrderId",
          key: "workOrderId",
          width: 200
        },
        {
          title: "Customer Name",
          dataIndex: "customer",
          key: "customer",
          width: 150
        },
        {
          title: "PIC",
          dataIndex: "picName",
          key: "picName",
          width: 150,
          scopedSlots: { customRender: "picName" }
        },
        {
          title: "Mechanic",
          dataIndex: "mechanic",
          key: "mechanic",
          // scopedSlots: { customRender: "productId" },
          width: 150,
          responsiveColSelect: [
            {
              name: "mechanic",
              placeholder: "Product",
              value: "id",
              options: [] as ResponseProduct[],
              style: "width: 100%"
            },
            {
              name: "status",
              placeholder: "Status",
              value: "",
              options: [
                {
                  name: "Finished",
                  id: 0
                },
                {
                  name: "Not Finished",
                  id: 1
                }
              ],
              style: "width: 100%"
            },
            {
              name: "reason",
              placeholder: "Reason",
              value: "",
              options: [
                {
                  name: "Finished",
                  id: 0
                },
                {
                  name: "Lightly Damaged",
                  id: 1
                },
                {
                  name: "Heavily Damaged",
                  id: 2
                }
              ],
              style: "width: 100%"
            }
          ],
          // responsiveColInput: [
          //   {
          //     name: "notes",
          //     placeholder: "Notes",
          //     disabled: "",
          //     style: "width: 100%"
          //   }
          // ],
          // responsiveColDateMonth: [
          //   {
          //     name: "date",
          //     placeholder: "Select Date",
          //     style: "width: 100%;",
          //     disabled: ""
          //   },
          // ],
        },
        {
          title: "Scheduled Order",
          dataIndex: "scheduledOrder",
          key: "scheduledOrder",
          width: 150
        },
        {
          title: "Notes",
          dataIndex: "description",
          key: "description",
          width: 150
        },
        {
          title: "Action",
          dataIndex: "operation",
          width: '120px',
          align: 'center',
          scopedSlots: { customRender: "operation" },
          fixed:'right',
          button: ['view'],
        },
      ],
      dataList: [] as DataResponseWorkOrderResult[]
    }
  },
  methods: {
    handleDownload () {
      console.log('heloo')
    },
    handlePrint () {
      console.log('heloo')
    },
    responseViewTable (response) {
      this.$router.push(`/logistic/workorder/result/view/${response.data.id}`)
    },
    responsePageSizeChange(response: ResponsePagination): void {
      this.limit = response.size
      this.page = 1;
      this.submitForm()
    },
    responseCurrentPageChange(response: ResponsePagination): void {
      this.page = response.page
      this.submitForm()
    },
    clearItems () {
      this.form.resetFields()
    },
    createNew () {
      this.$router.push('/logistic/workorder/result/create')
    },
    assignSearch (key, value, and): string {
      if (key === 'customerMaintenanceNumber' && value) return and + `secureId~${value}`
      else if (key === 'workOrderNumber' && value) return and + `workOrder.secureId~${value}`
      else if (key === 'dateFrom' && value) return and + `scheduledOrder>=${moment(value).set({hour: 0, minute: 0, second: 0}).utcOffset("+07").format()}`
      else if (key === 'dateTo' && value) return and + `scheduledOrder<=${moment(value).set({hour: 23, minute: 59, second: 59}).utcOffset("+07").format()}`
      else if (key === 'customerName' && value) return and + `customer.secureId~${value}`
      else if (key === 'unitCode' && value) return and + `resultWorkOrderLines.masterAsset~${value}`
      else return ''
    },
    dynamicSearch (res):string {
      let search = ''
      Object.keys(res).forEach((key) => {
        if(!search) {
          search = this.assignSearch(key, res[key], '')
        } else {
          search += this.assignSearch(key, res[key], '_AND_')
        }
      })
      return search
    },
    submitForm () {
      this.form.validateFields((err,res) => {
        if(err) return;
        let params = {
          page: this.page - 1,
          limit: this.limit,
        } as RequestQueryParamsModel
        params.search = this.dynamicSearch(res)
        this.loading.loadingFind = true
        this.loading.table = true
        logisticServices
          .getListResultWorkOrder(params)
          .then((response) => {
            this.totalData = response.totalElements
            this.dataList = response.data
          })
          .finally(() => {
            this.loading.loadingFind = false
            this.loading.table = false
          })
        console.log(res, 'res')
      })
    },
    getUnitCode (valueSearch) {
      let params = {
        page: 0,
        limit: 10,
      } as RequestQueryParamsModel
      if(valueSearch) params.search = `unitCode~*${valueSearch}*`
      this.loading.loadingUnitCode = true
      assetsServices
        .listMasterAsset(params)
        .then(data => this.dataUnitCode = data.data)
        .finally(() => this.loading.loadingUnitCode = false)
    },
    getListCustomer (valueSearch) {
      let params:RequestQueryParamsModel = {
        limit: 10,
        page: 0,
        search: 'customer~true' // active & incactive true while get list
      }
      if(valueSearch) params.search += `_AND_firstName~*${valueSearch}*_OR_lastName~*${valueSearch}*`
      this.loading.loadingCustomerName = true
      contactServices
        .listContactData(params)
        .then(response => this.dataCustomer = response.data)
        .finally(() => this.loading.loadingCustomerName = false)
    },
    getListCustomerMaintenanceNumber (valueSearch) {
      let params:RequestQueryParamsModel = {
        limit: 10,
        page: 0,
      }
      if(valueSearch) params.search = `workOrder.documentNumber~*${valueSearch}*`
      this.loading.loadingCustomerMaintenance = true
      logisticServices
        .getListResultWorkOrder(params)
        .then(response => this.dataListCustomerMaintenance = response.data)
        .finally(() => this.loading.loadingCustomerMaintenance = false)
    },
    getListWorkOrderNumber (valueSearch) {
      let params:RequestQueryParamsModel = {
        limit: 10,
        page: 0,
      }
      if(valueSearch) params.search = `workOrder.documentNumber~*${valueSearch}*`
      this.loading.loadingWorkOrderNumber = true
      logisticServices
        .getListWorkOrder(params)
        .then(response => this.dataWorkOrder = response.data)
        .finally(() => this.loading.loadingWorkOrderNumber = false)
    },
    getProducts(params: RequestQueryParamsModel): Promise<DataListProduct> {
      return productService.listProduct(params);
    },
    async getListProducts(): Promise<void> {
      try {
        const req: RequestQueryParamsModel = {
          limit: 10,
          page: 0
        };
        const res = await this.getProducts(req);
        const findColumn = this.columnsTable.find(column => column.responsiveColSelect)?.responsiveColSelect;
        if (findColumn) {
          findColumn[0].options = res.data;
        }
      } finally {
        console.log();
      }
    },
    filterOption (input, option) {
      return (
        option.componentOptions.children[0].componentOptions.children[1].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    },
  },
  computed: {
    formItemLayout() {
      return {
        labelCol: { span: 8 },
        wrapperCol: { span: 14 },
      };
    },
  },
  created() {
    this.getListProducts();
    this.getUnitCode('')
    this.getListCustomer('')
    this.getListCustomerMaintenanceNumber('')
    this.getListWorkOrderNumber('')
  }
});
