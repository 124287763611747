var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "a-layout-content",
        [
          _c(
            "a-row",
            { attrs: { gutter: [16, 16] } },
            [
              _c(
                "a-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "a-card",
                    { attrs: { title: "Result Customer Maintenance" } },
                    [
                      _c(
                        "a-row",
                        { attrs: { gutter: [16, 16] } },
                        [
                          _c(
                            "a-col",
                            { attrs: { span: 24 } },
                            [
                              _c(
                                "a-row",
                                { attrs: { gutter: [16, 16] } },
                                [
                                  _c(
                                    "a-col",
                                    { attrs: { span: 24 } },
                                    [
                                      _c(
                                        "a-form",
                                        _vm._b(
                                          {
                                            attrs: {
                                              layout: "vertical",
                                              form: _vm.form
                                            }
                                          },
                                          "a-form",
                                          _vm.formItemLayout,
                                          false
                                        ),
                                        [
                                          _c(
                                            "a-row",
                                            [
                                              _c(
                                                "a-col",
                                                {
                                                  attrs: {
                                                    span: 12,
                                                    xs: 24,
                                                    sm: 24,
                                                    md: 24,
                                                    lg: 12
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "a-form-model-item",
                                                    {
                                                      attrs: {
                                                        label:
                                                          _vm.formRules
                                                            .customerMaintenanceNumber
                                                            .label
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "a-select",
                                                        {
                                                          directives: [
                                                            {
                                                              name: "decorator",
                                                              rawName:
                                                                "v-decorator",
                                                              value:
                                                                _vm.formRules
                                                                  .customerMaintenanceNumber
                                                                  .decorator,
                                                              expression:
                                                                "formRules.customerMaintenanceNumber.decorator"
                                                            }
                                                          ],
                                                          attrs: {
                                                            name:
                                                              _vm.formRules
                                                                .customerMaintenanceNumber
                                                                .name,
                                                            placeholder:
                                                              _vm.formRules
                                                                .customerMaintenanceNumber
                                                                .placeholder,
                                                            showSearch: true,
                                                            "option-filter-prop":
                                                              "children",
                                                            "filter-option":
                                                              _vm.filterOption,
                                                            loading:
                                                              _vm.loading
                                                                .loadingCustomerMaintenance,
                                                            allowClear: true
                                                          },
                                                          on: {
                                                            search: function(
                                                              value
                                                            ) {
                                                              return _vm.getListCustomerMaintenanceNumber(
                                                                value
                                                              )
                                                            }
                                                          }
                                                        },
                                                        _vm._l(
                                                          _vm.dataListCustomerMaintenance,
                                                          function(
                                                            data,
                                                            index
                                                          ) {
                                                            return _c(
                                                              "a-select-option",
                                                              {
                                                                key: index,
                                                                attrs: {
                                                                  value: data.id
                                                                }
                                                              },
                                                              [
                                                                _c(
                                                                  "a-tooltip",
                                                                  [
                                                                    _c(
                                                                      "template",
                                                                      {
                                                                        slot:
                                                                          "title"
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          " " +
                                                                            _vm._s(
                                                                              data.documentNumber
                                                                            ) +
                                                                            " "
                                                                        )
                                                                      ]
                                                                    ),
                                                                    _vm._v(
                                                                      " " +
                                                                        _vm._s(
                                                                          data.documentNumber
                                                                        ) +
                                                                        " "
                                                                    )
                                                                  ],
                                                                  2
                                                                )
                                                              ],
                                                              1
                                                            )
                                                          }
                                                        ),
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "a-form-model-item",
                                                    {
                                                      attrs: {
                                                        label:
                                                          _vm.formRules
                                                            .workOrderNumber
                                                            .label
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "a-select",
                                                        {
                                                          directives: [
                                                            {
                                                              name: "decorator",
                                                              rawName:
                                                                "v-decorator",
                                                              value:
                                                                _vm.formRules
                                                                  .workOrderNumber
                                                                  .decorator,
                                                              expression:
                                                                "formRules.workOrderNumber.decorator"
                                                            }
                                                          ],
                                                          attrs: {
                                                            name:
                                                              _vm.formRules
                                                                .workOrderNumber
                                                                .name,
                                                            placeholder:
                                                              _vm.formRules
                                                                .workOrderNumber
                                                                .placeholder,
                                                            showSearch: true,
                                                            "option-filter-prop":
                                                              "children",
                                                            "filter-option":
                                                              _vm.filterOption,
                                                            loading:
                                                              _vm.loading
                                                                .loadingWorkOrderNumber,
                                                            allowClear: true
                                                          },
                                                          on: {
                                                            search: function(
                                                              value
                                                            ) {
                                                              return _vm.getListWorkOrderNumber(
                                                                value
                                                              )
                                                            }
                                                          }
                                                        },
                                                        _vm._l(
                                                          _vm.dataWorkOrder,
                                                          function(
                                                            data,
                                                            index
                                                          ) {
                                                            return _c(
                                                              "a-select-option",
                                                              {
                                                                key: index,
                                                                attrs: {
                                                                  value:
                                                                    data.workOrderNumber
                                                                }
                                                              },
                                                              [
                                                                _c(
                                                                  "a-tooltip",
                                                                  [
                                                                    _c(
                                                                      "template",
                                                                      {
                                                                        slot:
                                                                          "title"
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          " " +
                                                                            _vm._s(
                                                                              "" +
                                                                                data.workOrderNumber
                                                                            ) +
                                                                            " "
                                                                        )
                                                                      ]
                                                                    ),
                                                                    _vm._v(
                                                                      " " +
                                                                        _vm._s(
                                                                          "" +
                                                                            data.workOrderNumber
                                                                        ) +
                                                                        " "
                                                                    )
                                                                  ],
                                                                  2
                                                                )
                                                              ],
                                                              1
                                                            )
                                                          }
                                                        ),
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "a-form-model-item",
                                                    {
                                                      attrs: {
                                                        label:
                                                          _vm.formRules
                                                            .customerName.label
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "a-select",
                                                        {
                                                          directives: [
                                                            {
                                                              name: "decorator",
                                                              rawName:
                                                                "v-decorator",
                                                              value:
                                                                _vm.formRules
                                                                  .customerName
                                                                  .decorator,
                                                              expression:
                                                                "formRules.customerName.decorator"
                                                            }
                                                          ],
                                                          attrs: {
                                                            name:
                                                              _vm.formRules
                                                                .customerName
                                                                .name,
                                                            placeholder:
                                                              _vm.formRules
                                                                .customerName
                                                                .placeholder,
                                                            showSearch: true,
                                                            "option-filter-prop":
                                                              "children",
                                                            "filter-option":
                                                              _vm.filterOption,
                                                            loading:
                                                              _vm.loading
                                                                .loadingCustomerName,
                                                            allowClear: true
                                                          },
                                                          on: {
                                                            search: function(
                                                              value
                                                            ) {
                                                              return _vm.getListCustomer(
                                                                value
                                                              )
                                                            }
                                                          }
                                                        },
                                                        _vm._l(
                                                          _vm.dataCustomer,
                                                          function(
                                                            data,
                                                            index
                                                          ) {
                                                            return _c(
                                                              "a-select-option",
                                                              {
                                                                key: index,
                                                                attrs: {
                                                                  value: data.id
                                                                }
                                                              },
                                                              [
                                                                _c(
                                                                  "a-tooltip",
                                                                  [
                                                                    _c(
                                                                      "template",
                                                                      {
                                                                        slot:
                                                                          "title"
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          " " +
                                                                            _vm._s(
                                                                              data.fullName.replace(
                                                                                "null",
                                                                                ""
                                                                              )
                                                                            ) +
                                                                            " "
                                                                        )
                                                                      ]
                                                                    ),
                                                                    _vm._v(
                                                                      " " +
                                                                        _vm._s(
                                                                          data.fullName.replace(
                                                                            "null",
                                                                            ""
                                                                          )
                                                                        ) +
                                                                        " "
                                                                    )
                                                                  ],
                                                                  2
                                                                )
                                                              ],
                                                              1
                                                            )
                                                          }
                                                        ),
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              ),
                                              _c(
                                                "a-col",
                                                {
                                                  attrs: {
                                                    span: 12,
                                                    xs: 24,
                                                    sm: 24,
                                                    md: 24,
                                                    lg: 12
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "a-form-model-item",
                                                    {
                                                      attrs: {
                                                        label:
                                                          _vm.formRules.dateFrom
                                                            .label
                                                      }
                                                    },
                                                    [
                                                      _c("a-date-picker", {
                                                        directives: [
                                                          {
                                                            name: "decorator",
                                                            rawName:
                                                              "v-decorator",
                                                            value:
                                                              _vm.formRules
                                                                .dateFrom
                                                                .decorator,
                                                            expression:
                                                              "formRules.dateFrom.decorator"
                                                          }
                                                        ],
                                                        attrs: {
                                                          name:
                                                            _vm.formRules
                                                              .dateFrom.name,
                                                          placeholder:
                                                            _vm.formRules
                                                              .dateFrom
                                                              .placeholder,
                                                          format: "DD-MMM-yyyy"
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "a-form-model-item",
                                                    {
                                                      attrs: {
                                                        label:
                                                          _vm.formRules.dateTo
                                                            .label
                                                      }
                                                    },
                                                    [
                                                      _c("a-date-picker", {
                                                        directives: [
                                                          {
                                                            name: "decorator",
                                                            rawName:
                                                              "v-decorator",
                                                            value:
                                                              _vm.formRules
                                                                .dateTo
                                                                .decorator,
                                                            expression:
                                                              "formRules.dateTo.decorator"
                                                          }
                                                        ],
                                                        attrs: {
                                                          name:
                                                            _vm.formRules.dateTo
                                                              .name,
                                                          placeholder:
                                                            _vm.formRules.dateTo
                                                              .placeholder,
                                                          format: "DD-MMM-yyyy"
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "a-row",
                                [
                                  _c(
                                    "a-col",
                                    {
                                      staticClass: "mt-2 mb-2",
                                      staticStyle: {
                                        display: "flex",
                                        "justify-content": "flex-end"
                                      },
                                      attrs: { span: 24 }
                                    },
                                    [
                                      _c(
                                        "a-button",
                                        {
                                          staticStyle: {
                                            width: "180px",
                                            "margin-right": "1rem"
                                          },
                                          attrs: { type: "danger" },
                                          on: { click: _vm.clearItems }
                                        },
                                        [
                                          _c("a-icon", {
                                            staticStyle: {
                                              "vertical-align": "0"
                                            },
                                            attrs: { type: "delete" }
                                          }),
                                          _vm._v(" Clear ")
                                        ],
                                        1
                                      ),
                                      _c(
                                        "a-button",
                                        {
                                          staticStyle: {
                                            width: "180px",
                                            "margin-right": "1rem"
                                          },
                                          attrs: {
                                            type: "primary",
                                            loading: _vm.loading.loadingFind
                                          },
                                          on: { click: _vm.submitForm }
                                        },
                                        [
                                          _c("a-icon", {
                                            staticStyle: { verticalAlign: "0" },
                                            attrs: { type: "search" }
                                          }),
                                          _vm._v(" Find Data ")
                                        ],
                                        1
                                      ),
                                      _c(
                                        "a-button",
                                        {
                                          staticStyle: { width: "180px" },
                                          attrs: { type: "primary" },
                                          on: { click: _vm.createNew }
                                        },
                                        [_vm._v(" Create New ")]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "a-row",
                                { attrs: { gutter: [16, 16] } },
                                [
                                  _c(
                                    "a-col",
                                    { attrs: { span: 24 } },
                                    [
                                      _c("TableCustom", {
                                        attrs: {
                                          idTable: "tableresultworkorder",
                                          dataSource: _vm.dataList,
                                          columns: _vm.columnsTable,
                                          paginationcustom: true,
                                          defaultPagination: false,
                                          loading: _vm.loading.table,
                                          scroll: { x: 2600 }
                                        },
                                        on: { "on-view": _vm.responseViewTable }
                                      })
                                    ],
                                    1
                                  ),
                                  _c("a-col", { attrs: { span: 12 } }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "d-flex align-items-center"
                                      },
                                      [
                                        _c("Pagination", {
                                          attrs: {
                                            idPagination:
                                              "paginationresultworkorder",
                                            total: _vm.totalData,
                                            pageSizeSet: _vm.limit
                                          },
                                          on: {
                                            "response-pagesize-change":
                                              _vm.responsePageSizeChange,
                                            "response-currentpage-change":
                                              _vm.responseCurrentPageChange
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ]),
                                  _c(
                                    "a-col",
                                    { attrs: { span: 12, align: "end" } },
                                    [
                                      _c(
                                        "a-tag",
                                        {
                                          staticStyle: { "font-size": "13px" },
                                          attrs: { color: "grey" }
                                        },
                                        [
                                          _vm._v(
                                            " Total Found : " +
                                              _vm._s(_vm.totalData) +
                                              " "
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "a-col",
                                    { attrs: { span: 24, align: "end" } },
                                    [
                                      _c(
                                        "a-space",
                                        [
                                          _c(
                                            "a-button",
                                            {
                                              attrs: { type: "primary" },
                                              on: { click: _vm.handleDownload }
                                            },
                                            [
                                              _c("a-icon", {
                                                staticStyle: {
                                                  verticalAlign: "0"
                                                },
                                                attrs: { type: "download" }
                                              }),
                                              _vm._v(" Download ")
                                            ],
                                            1
                                          ),
                                          _c(
                                            "a-button",
                                            {
                                              attrs: { type: "primary" },
                                              on: { click: _vm.handlePrint }
                                            },
                                            [
                                              _c("a-icon", {
                                                staticStyle: {
                                                  verticalAlign: "0"
                                                },
                                                attrs: { type: "printer" }
                                              }),
                                              _vm._v("Print ")
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }